import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { graphql } from "gatsby";
import {
  Section,
  SectionPageTitle,
  SectionBanner,
  BreadCrumb,
} from "../components/section";
import Layout from "../components/layout";
import SEO from "../components/seo";
import SitemapBanner from "../images/privacy-policy-banner.jpg";
import BreakpointUp from "../components/Media/BreakpointUp";

const SitemapGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 30px;
    ${BreakpointUp.sm`    
        grid-template-columns: repeat(3, 1fr);     
    `}
`;
const SitemapItem = styled.div`
  & h2{
      font-weight: 700;
      font-size: 28px;
      line-height:38px;
      margin-bottom:10px;
      & a{
          color:#002337;
          &:hover {
              color:#002337;
          }
      }
  }
  & ul{
      & li{
          & a{
              color: rgba(102, 102, 102, 0.7);
              &:hover {
                  color:#002337;
              }
          }  
      }
  }
`;
const SitemapPage = ({ data }) => {
  const products = data.allContentfulProduct;
  const len = data.allContentfulProduct.edges.length;
  return (
    <Layout>
      <SEO title="Sitemap" description="Sitemap" />
      <SectionBanner bgImg={`url(${SitemapBanner})`}>
        <div className="container">
          <BreadCrumb>
            <Link to="/">Home</Link>/<span>Sitemap</span>
          </BreadCrumb>
          <SectionPageTitle mt="30px" mb="0px" textAlign="center">
            Sitemap
          </SectionPageTitle>
        </div>
      </SectionBanner>
      <Section pt="90px" pb="90px" xpt="60px" xpb="60px" maxWidth="1120px">
        <div className="container">
          <SitemapGrid>
            <SitemapItem>
              <h2>
                <Link to="/metal-buildings">Metal Buildings</Link>
              </h2>
              <ul>
                <li><Link to="/metal-building-homes"><span>Metal Building Homes</span></Link></li>
                <li><Link to="/commercial-metal-buildings"><span>Commercial Buildings</span></Link></li>
                <li><Link to="/clear-span-buildings"><span>Clear Span Buildings</span></Link></li>
                <li><Link to="/custom-metal-buildings"><span>Custom Buildings</span></Link></li>
                <li><Link to="/metal-workshops"><span>Metal Workshops</span></Link></li>
                <li><Link to="/metal-warehouses"><span>Metal Warehouses</span></Link></li>
              </ul>
              <h2>
                <Link to="/metal-garages">Garages</Link>
              </h2>
              <ul>
                <li><Link to="/vertical-roof-garages"><span>A-Frame Vertical Garages</span></Link></li>
                <li><Link to="/a-frame-roof-garages"><span>A-Frame Horizontal Garages</span></Link></li>
                <li><Link to="/regular-roof-garages"><span>Regular Garages</span></Link></li>
                <li><Link to="/one-car-garages"><span>One Car Garages</span></Link></li>
                <li><Link to="/two-car-garages"><span>Two Car Garages</span></Link></li>
                <li><Link to="/three-car-garages"><span>Three Car Garages</span></Link></li>
              </ul>
              <h2>
                <Link to="/metal-barns">Barns</Link>
              </h2>
              <ul>
                <li><Link to="/horse-barns"><span>Horse Barns</span></Link></li>
                <li><Link to="/continuous-roof-barns/"><span>Continuous Roof Barns</span></Link></li>
                <li><Link to="/vertical-roof-barns"><span>A-Frame Vertical Roof Barns</span></Link></li>
                <li><Link to="/a-frame-roof-barns"><span>A-Frame Horizontal Roof Barns</span></Link></li>
                <li><Link to="/regular-roof-barns"><span>Regular Roof Barns</span></Link></li>
              </ul>
              <h2>
                <Link to="/metal-carports">Carports</Link>
              </h2>
              <ul>

                <li><Link to="/vertical-roof-carports"><span>A-Frame Vertical Carports</span></Link></li>
                <li><Link to="/a-frame-roof-carports"><span>A-Frame Horizontal Carports</span></Link></li>
                <li><Link to="/regular-roof-carports"><span>Regular Carports</span></Link></li>
                <li><Link to="/one-car-carports"><span>One Car Carports</span></Link></li>
                <li><Link to="/two-car-carports"><span>Two Car Carports</span></Link></li>
                <li><Link to="/three-car-carports"><span>Three Car Carports</span></Link></li>
              </ul>
              <h2>RTO/Financing</h2>
              <ul>
                <li><Link to='/rto'><span>RTO</span></Link></li>
                <li><Link to='/financing'><span>Financing</span></Link></li>
              </ul>

              <h2>Resources</h2>
              <ul>
                <li><Link to='/leveling-guide'><span>Leveling Guide</span></Link></li>
                <li><Link to='/metal-building-roof-guide'><span>Metal Building Roof Guide</span></Link></li>
                {/* <li><Link to='/estimated-delivery-time'><span>Estimated Delivery Time</span></Link></li> */}
                <li><Link to='/color-options'><span>Color Options</span></Link></li>
                <li><Link to='/blog'><span>Blog</span></Link></li>
                <li><Link to='/faq'><span>FAQs</span></Link></li>			
              </ul>
              <h2>
                <Link to="/about-us">About Us</Link>
              </h2>
              <ul>
                <li>
                  <Link to="/contact-us">Contact Us</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
              </ul>
            </SitemapItem>
            <SitemapItem>
              <h2>
                <Link to="/products">Products</Link>
              </h2>
              <ul>
                {products.edges.map((item, i) => {
                  if (i < len / 2) {
                    return (
                      <li>
                        <Link to={item.node.url}>{item.node.productName}</Link>
                      </li>
                    );
                  }
                  return null;
                })}
              </ul>
            </SitemapItem>
            <SitemapItem>
              <ul>
                {products.edges.map((item, i) => {
                  if (i >= len / 2) {
                    return (
                      <li>
                        <Link to={item.node.url}>{item.node.productName}</Link>
                      </li>
                    );
                  }
                  return null;
                })}
              </ul>
            </SitemapItem>
          </SitemapGrid>
        </div>
      </Section>
    </Layout>
  );
};

export default SitemapPage;

export const pageQuery = graphql`
  query SitemapPageQuery {
    allContentfulProduct {
      edges {
        node {
          productName
          url
        }
      }
    }
  }
`;
